export const delay = 3000;

export const message = {
  common: {
    ok: 'Ok',
  },
  error: {
    default: 'Ocurrió un error.',
  },
};
