import { Injectable } from '@angular/core';
import { FormGroup, ValidationErrors } from '@angular/forms';
import { Capacitor } from '@capacitor/core';
import { lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { GlobalConsts } from '@/app/shared/enums';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor(private readonly httpClient: HttpClient) {}

  getError(formGroup: FormGroup<any>, controlName: string) {
    const control = formGroup.get(controlName);
    if (control?.errors && control.dirty && control.touched) {
      const errors = control.errors;
      const errorKey = Object.keys(errors).find((err) => err == 'pattern');
      if (errors['required']) {
        return 'Campo requerido';
      } else if (errors['email']) {
        return 'Formato de correo electrónico inválido';
      } else if (errors['pattern'] && errorKey) {
        return this.getRegExError(errors, errorKey);
      } else if (errors['minlength']) {
        return `Debe tener al menos ${errors['minlength'].requiredLength} caracteres`;
      } else if (errors['maxlength']) {
        return `Debe tener como máximo ${errors['maxlength'].requiredLength} caracteres`;
      } else if (errors['wrongNumber']) {
        return `El número ingresado es incorrecto`;
      }
    }
    return '';
  }

  private getRegExError(errors: ValidationErrors, errorKey: string) {
    if (errorKey !== 'pattern') return '';
    const pattern = errors['pattern'].requiredPattern;
    const regEx = GlobalConsts.regEx;
    if (pattern === regEx.onlyLettersWithNoTrim.toString()) {
      return 'Solo letras, sin numeros.';
    } else if (pattern === regEx.isNumber.toString()) {
      return 'Solo números.';
    }

    return '';
  }

  async log(message?: string): Promise<any> {
    return lastValueFrom(
      this.httpClient.post(GlobalConsts.endpoints.log, { message })
    );
  }
}

export function isEmptyObject(obj: Object) {
  return Object.keys(obj).length === 0;
}

export function isMobile() {
  return Capacitor.isNativePlatform();
}

export const sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const getRandomInt = (max: number) => {
  return Math.floor(Math.random() * max);
};

export const splitArrayIntoParts = <T>(array: T[], N: number) => {
  const partSize = Math.ceil(array.length / N); // Calculate approximate size of each part
  const result = [];

  for (let i = 0; i < array.length; i += partSize) {
    result.push(array.slice(i, i + partSize)); // Slice each part and add it to the result
  }

  return result;
};

export const getMimeTypeByExtension = (fileUrl: string): string => {
  const splitedFileUrl = fileUrl.split('.');
  const extension = splitedFileUrl[splitedFileUrl.length - 1];

  switch (extension) {
    case 'pdf':
      return 'application/pdf';
    case 'mp3':
      return 'audio/mp3';
    case 'image':
      return 'image/png';
    default:
      return 'text/plain';
  }
};
