import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { IonicModule } from '@ionic/angular'

import * as BARREL from './index'

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, IonicModule],
  declarations: [
    BARREL.IncommingCallComponent,
    BARREL.InputFieldComponent,
    BARREL.LoaderComponent,
  ],
  exports: [
    BARREL.IncommingCallComponent,
    BARREL.InputFieldComponent,
    BARREL.LoaderComponent,
  ],
})
export class SharedModule {}
