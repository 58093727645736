import { Injectable } from '@angular/core'
import {
	ModalController,
	ToastController,
} from '@ionic/angular'
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class ControlService {
	public isModalOpen = false
  public loading$ = new BehaviorSubject(false);

  constructor(
    private toastCtrl: ToastController,
    private modalCtrl: ModalController,
    // private loadingCtrl: LoadingController,
  ) {}

  public readonly loader = {
    show: () => {
      this.loading$.next(true)
    },
    dismiss: () => {
      this.loading$.next(false)
    },
  }

  public readonly toast = {
    show: async ({
      message,
      color,
      duration = 3000,
      position = 'bottom',
    }: ControlServiceToastInit) => {
      const toastInstance = await this.toastCtrl.create({
        message,
        duration,
        color,
        position,
      })

      toastInstance.present()

      return toastInstance
    },
    /** @deprecated */
    dismiss: () => {},
  }

  public readonly modal = {
    show: async (init: ControlServiceModalInit) => {
      const modalInstance = await this.modalCtrl.create({
        component: init.component,
        componentProps: init.componentProps,
        cssClass: init.cssClass,
        backdropDismiss: init.backdropDismiss,
      })

      await modalInstance.present()

			this.isModalOpen = true

      return modalInstance
    },
    dismiss: (data?: unknown) => {
      this.modalCtrl.dismiss(data).catch(console.error)
			this.isModalOpen = true
    },
  }
}
