import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { compareDesc, differenceInDays, format, parseISO } from 'date-fns'
import { BehaviorSubject, catchError, lastValueFrom, map } from 'rxjs'

import { ListUsersToChat } from '@/app/mocks/data/contact-lists.mock'
import { GlobalConsts } from '@/app/shared/enums/index'
import { AppService } from './app.service'
import { AuthService } from './auth.service'
import { ContactsService } from './contacts.service'
import { CryptoService } from './crypto.service'

@Injectable({
  providedIn: 'root',
})
export class ChatsService {
  chats$: BehaviorSubject<Chat[]> = new BehaviorSubject<Chat[]>([])

  callToReloadChats$: BehaviorSubject<undefined | any> = new BehaviorSubject<
    undefined | any
  >(undefined)

  callToReloadChats() {
    this.callToReloadChats$.next(true)
  }

  constructor(
    private appService: AppService,
    private authService: AuthService,
    private cryptoService: CryptoService,
    private contactsService: ContactsService,
    private httpClient: HttpClient,
  ) {}

  async getChats(contacts: GetContactsRequestBody[]): Promise<Chat[]> {
    const url = GlobalConsts.endpoints.chats.default
    return lastValueFrom(
      this.httpClient.post(url, { contacts }).pipe(
        map(async (r) => {
          const chatsPromise = (r as Chat[])
            .filter((x) => x.UserChat.length)
            .map((x) =>
              this.cryptoService.decryptChat(
                x,
                this.authService.authData.value?.user.id!,
                x.UserChat[0].User.id,
              ),
            )
          const chats = await Promise.all(chatsPromise)
          this.chats$.next(chats)
          return chats
        }),
        catchError((error) =>
          this.appService.handleBackendError('getChats', error),
        ),
      ),
    )
  }

  async getChat(targetId: string, prev?: Chat): Promise<Chat> {
    const url = GlobalConsts.endpoints.chats.chat(targetId)
    return lastValueFrom(
      this.httpClient.get(url).pipe(
        map((r) => {
          return this.cryptoService.decryptChat(
            r as Chat,
            this.authService.authData.value?.user.id!,
            targetId,
            prev,
          )
        }),
        catchError((error) =>
          this.appService.handleBackendError('getChats', error),
        ),
      ),
    )
  }

  async forceGetChat(targetId: string): Promise<Chat> {
    const url = GlobalConsts.endpoints.chats.force(targetId)
    return lastValueFrom(
      this.httpClient.get(url).pipe(
        map((r: any) => {
          r.UserChat = (r as Chat).UserChat.filter(
            (x) => x.User.id === targetId,
          )
          r = this.cryptoService.decryptChat(
            r as Chat,
            this.authService.authData.value?.user.id!,
            targetId,
          )
          return r
        }),
        catchError((error) =>
          this.appService.handleBackendError('forceGetChat', error),
        ),
      ),
    )
  }

  private getRelativeDate = (dateStr: string) => {
    const today = new Date()
    const targetDate = new Date(dateStr)

    const diffInDays = differenceInDays(targetDate, today)

    if (diffInDays === 0) return 'Hoy'
    if (diffInDays === 1) return 'Ayer'
    return format(targetDate, 'd LLLL y')
  }

  groupMessages(messages: Message[]): GroupMessage[] {
    const copy = [...messages];
    const groups: GroupMessage[] = [];
  
    // setup date containers
    for (let i = 0; i < copy.length; i++) {
      const message = messages[i];
      const relativeTime = this.getRelativeDate(message.createdAt);
      let aux = groups.find((g) => g.date === relativeTime);
  
      if (!aux) {
        aux = {
          date: relativeTime,
          senders: [],
        };
  
        groups.push(aux);
      }
    }

    // group by date
    for (let i = 0; i < copy.length; i++) {
      const message = messages[i];
      const relativeTime = this.getRelativeDate(message.createdAt);
      groups
        .find((g) => g.date === relativeTime)
        ?.senders.push(message as any);
    }

    // group up senders
    for (let i = 0; i < groups.length; i++) {
      let senders: SenderGroup[] = [];
      const g = groups[i];
      let aux = [];
  
      for (let j = 0; j < g.senders.length; j++) {
        const msg = g.senders[j] as unknown as Message;
        const prevMsg = g.senders[j - 1] as unknown as Message;
  
        if (msg?.senderId === prevMsg?.senderId) {
          aux.push(msg);
        } else {
          aux = [];
          aux.push(msg);
          senders.push({ senderId: msg?.senderId || '', messages: aux });
        }
      }
  
      g.senders = senders;
      senders = []
    }

    const reversedGroups: any = groups.map(g => {
      return {
        date: g.date,
        senders: g.senders.reverse().map(s => {
          return {
            senderId: s.senderId,
            messages: s.messages.reverse()
          }
        })
      }
    });

    return reversedGroups;
  }

  getChatHistorial(chatId: string) {
    const url = GlobalConsts.endpoints.chats.historial + `/${chatId}`
    return lastValueFrom(
      this.httpClient.get(url).pipe(
        map((r) => r as Message[]),
        catchError((error) =>
          this.appService.handleBackendError('getChats', error),
        ),
      ),
    )
  }

  parseMessagesByDate(messages: Message[]) {
    return messages.reduce((acc: any, message) => {
      const date = format(parseISO(message.createdAt.toString()), 'yyyy-MM-dd')
      if (!acc[date]) {
        acc[date] = []
      }
      acc[date].push(message)
      return acc
    }, {})
  }

  search(body: ChatsSearchRequestBody) {
    const url = GlobalConsts.endpoints.chats.search
    return lastValueFrom(
      this.httpClient.post<ListUsersToChat>(url, body).pipe(
        map((r) => r),
        catchError((error) =>
          this.appService.handleBackendError('getChats', error),
        ),
      ),
    )
  }

  deleteChats(chatsId: string[]) {
    return lastValueFrom(
      this.httpClient
        .delete(GlobalConsts.endpoints.chats.default, { body: chatsId })
        .pipe(
          catchError((e) =>
            this.appService.handleBackendError('deleteChats', e),
          ),
        ),
    )
  }
  sortByLastMessage(chats: Chat[], senderId: string): Chat[] {
    const sortedChats = chats.sort((a, b) => {
      const lastMessageA = a.Message?.[a.Message.length - 1]!
      const lastMessageB = b.Message?.[b.Message.length - 1]!

      const hasUnreadA =
        lastMessageA.readedAt === null && lastMessageA.senderId !== senderId
      const hasUnreadB =
        lastMessageB.readedAt === null && lastMessageB.senderId !== senderId

      if (hasUnreadA && !hasUnreadB) return -1
      if (!hasUnreadA && hasUnreadB) return 1

      return compareDesc(
        parseISO(lastMessageA.createdAt),
        parseISO(lastMessageB.createdAt),
      )
    })

    return sortedChats
  }

  refeshChats() {
    const url = GlobalConsts.endpoints.chats.default
    const contacts = this.contactsService.contactsListFlat$.getValue()
    return lastValueFrom(
      this.httpClient.post(url, { contacts }).pipe(
        map(async (r) => {
          const chats = (r as Chat[]).filter((x) => x.UserChat.length)
          const promises = chats.map((x) =>
            this.cryptoService.decryptChat(
              x,
              this.authService.authData.value?.user.id!,
              x.UserChat[0].User.id,
            ),
          )
          this.chats$.next(await Promise.all(promises))
        }),
      ),
    )
  }

  checkMissedCall() {
    const url = GlobalConsts.endpoints.calls.missedCalls
    return lastValueFrom(this.httpClient.get(url).pipe(map((r) => r)))
  }

  async getOrCreateChat(userToId: string): Promise<Chat> {
    return lastValueFrom(
      this.httpClient
        .get(GlobalConsts.endpoints.chats.getOrCreateChat(userToId))
        .pipe(
          map(async (x) =>
            this.cryptoService.decryptChat(
              x as Chat,
              this.authService.authData.value?.user.id!,
              userToId,
            ),
          ),
          catchError((e) =>
            this.appService.handleBackendError('getOrCreateChat', e),
          ),
        ),
    )
  }
}
