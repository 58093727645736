@if (isLoading) {
    <section
        class="absolute top-0 left-0 w-full min-h-[100dvh] grid place-items-center bg-[rgb(0_0_0/50%)] z-50"
    >
        <div
            role="loader"
            class="grid grid-cols-2 grid-rows-2 w-12 h-12 gap-0.5"
        >
            <span data-id="a" class="rounded-[6px] animate-fadeinout"></span>
            <span data-id="b" class="rounded-[6px] animate-fadeinout"></span>
            <span data-id="c" class="rounded-[6px] animate-fadeinout"></span>
            <span data-id="d" class="rounded-[6px] animate-fadeinout"></span>
        </div>
    </section>
}

@if (forced) {

        <div
            role="loader"
            class="absolute top-2/4 left-2/4 translate-x-[-50%] translate-y-[-50%] grid grid-cols-2 grid-rows-2 w-10 h-10 gap-0.5"
        >
            <span data-id="a" class="rounded-[6px] animate-fadeinout"></span>
            <span data-id="b" class="rounded-[6px] animate-fadeinout"></span>
            <span data-id="c" class="rounded-[6px] animate-fadeinout"></span>
            <span data-id="d" class="rounded-[6px] animate-fadeinout"></span>
        </div>
}
