import { GlobalConsts } from '@/app/shared/enums';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppService {

  constructor() {}

  handleBackendError(operation: string, error: any) {
    const message = getErrorMessage(error);
    console.log(`${operation} failed: ${message}`);

    return throwError(() => new Error(message));
  }
}

export const getErrorMessage = (error: HttpErrorResponse) => {
  let auxMessage = '';

  const msg = error.error.message;
  if (typeof msg === 'string') auxMessage = error.error.message;
  else if (typeof msg === 'object') auxMessage = error.error?.message?.[0];

  return auxMessage ?? `${GlobalConsts.message.error.default}`;
};
