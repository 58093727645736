import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormGroup } from '@angular/forms'

@Component({
  selector: 'app-input-field',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.scss'],
})
export class InputFieldComponent implements OnInit {
  @Output() change = new EventEmitter<string>()

  @Input() label!: string
  @Input() controlName!: string
  @Input() formGroup!: FormGroup
  @Input() inputType!: HTMLInputElement['type']
  @Input() placeholder!: string
  @Input() h = 45

  constructor() {}

  ngOnInit() {}

  onChange(event: Event) {
    this.change.emit((event.target as any).value)
  }
}
