import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { catchError, lastValueFrom, map } from 'rxjs'

import { GlobalConsts } from '@/app/shared/enums'
import { AppService } from './app.service'

@Injectable({
  providedIn: 'root',
})
export class CallService {
  constructor(
    private httpClient: HttpClient,
    private appService: AppService,
  ) {}

  getUserCallbyCallId(callId: string): Promise<UserCall> {
    return lastValueFrom(
      this.httpClient.get(GlobalConsts.endpoints.calls.call(callId)).pipe(
        map((r) => r as UserCall),
        catchError((error: any) =>
          this.appService.handleBackendError('getUserCallbyCallId', error),
        ),
      ),
    )
  }
}
