import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, catchError, lastValueFrom, map } from 'rxjs'

import { GlobalConsts } from '@/app/shared/enums'
import { AppService } from './app.service'
import { StorageService } from './storage.service'

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  authData = new BehaviorSubject<AuthData | null>(null)

  constructor(
    private readonly storageService: StorageService,
    private readonly appService: AppService,
    private readonly httpClient: HttpClient,
  ) {}

  public async isLoggedIn(): Promise<boolean> {
    const localState = this.authData.getValue()
    const dbState = await this.storageService.findOne<AuthData>(
      GlobalConsts.storage.authData,
    )

    return (localState ?? dbState) !== null
  }

  private async updateLoginData(authData: AuthData): Promise<void> {
    this.storageService.insert(
      GlobalConsts.storage.authData,
      JSON.stringify(authData),
    )

    this.authData.next(authData)
  }

  async login(credentials: LoginCredentials): Promise<AuthData> {
    const url = GlobalConsts.endpoints.auth.login

    return lastValueFrom(
      this.httpClient.post<AuthData>(url, credentials).pipe(
        map((data: AuthData) => {
          this.updateLoginData(data)
          return data
        }),
        catchError((error: any) =>
          this.appService.handleBackendError('login', error),
        ),
      ),
    )
  }

  getUser() {
    return this.authData.getValue()!.user
  }

  register(credentials: RegisterCredentials): Promise<AuthData> {
    const url = GlobalConsts.endpoints.auth.register

    return lastValueFrom(
      this.httpClient.post<AuthData>(url, credentials).pipe(
        map((data: AuthData) => {
          this.updateLoginData(data)
          return data
        }),
        catchError((error: any) =>
          this.appService.handleBackendError('register', error),
        ),
      ),
    )
  }

  async loadAuthData(): Promise<void> {
    const storageAuthData = await this.storageService.findOne<AuthData>(
      GlobalConsts.storage.authData,
    )

    if (storageAuthData) {
      this.authData.next(storageAuthData)
    }
  }

  async refreshAuthUser({ token }: AuthData): Promise<any> {
    const url = GlobalConsts.endpoints.auth.me

    return lastValueFrom(
      this.httpClient.get(url).pipe(
        map(async (data) => {
          const user = data as User
          await this.updateLoginData({ token, user })
          return user
        }),
        catchError((error) =>
          this.appService.handleBackendError('refreshAuthUser', error),
        ),
      ),
    )
  }

  async resetOSId() {
    await lastValueFrom(
      this.httpClient.post(GlobalConsts.endpoints.auth.logout, undefined),
    )
  }

  async logout() {
    this.storageService.delete(GlobalConsts.storage.authData)
    this.authData.next(null)
  }
}
